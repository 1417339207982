import styled from 'styled-components'

export const WeatherContainer = styled.div`
  width: 100%;
  padding: 1em;
  height: 100%;
  display: flex;
  align-items: center;
  & :first-child {
    width: 100%;
    height: fit-content;
  }
  & .header-0-2-3 {
    font-weight: 500;
    font-size: 20px;
    letter-spacing: 0;
  }
  & .left-0-2-4 {
    text-align: left;
    padding: unset;
    font-size: 14px;
    font-weight: 400;
  }
  & .right-0-2-5 {
    background: unset;
  }
  & .hr-0-2-13 {
    border: unset;
    margin: 0px;
  }
  & .hr-0-2-15 {
    display: none;
  }
`
