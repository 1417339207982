import React, { Fragment } from 'react'
import {
  ImageContainer,
  Image,
  SubTitle,
  TextContainer,
  Text,
  Title,
} from './style'

const Bio = () => {
  return (
    <Fragment>
      <ImageContainer>
        <Image />
      </ImageContainer>
      <TextContainer>
        <Title>Azul María Prieto</Title>
        <SubTitle>UX - UI Designer</SubTitle>
        <Text>
          I’m a web UX-UI designer, creative and multidisciplinary. Each created
          project has its own personality. I’m a communication problem’s solver,
          my strengths are Ux Research, visual design and Product design. My
          passions are illustration and digital art
        </Text>
      </TextContainer>
    </Fragment>
  )
}

export default Bio
