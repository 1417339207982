import React, { useState } from 'react'
import ReactDom from 'react-dom'
import { Routes, Route } from 'react-router-dom'
import { BrowserRouter } from 'react-router-dom'
import { MuuriComponent } from 'muuri-react'
import { options } from './utils/utils'
import { lightTheme, darkTheme } from './utils/customStyles'
import { ThemeProvider, createGlobalStyle } from 'styled-components'

// Hooks
import useItems from './hooks/useItems'
import useFilter from './hooks/useFilter'

//Layouts
import Header from './layout/Header'
import Main from './layout/Main'
import Item from './components/Item'
import Filters from './components/Filters'
import Switch from '../src/components/cards/Switch'
import Project from './views/Project'

import './style.css'

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    width: 100vw;
    min-height: 100vh;
    background: ${props => props.theme.mainBg};
    font-family: 'Inter', sans-serif;
    color: ${props => props.theme.color} !important;
  }
`

// App.
const App = () => {
  const [theme, setTheme] = useState(lightTheme)
  const [filter, setFilter] = useState({ value: 'home' })

  const handleThemeSwitch = () => {
    if (theme === lightTheme) {
      setTheme(darkTheme)
    } else {
      setTheme(lightTheme)
    }
  }

  const switchComponent = (
    <Switch
      activeTheme={theme === lightTheme ? 0 : 1}
      handleSwitch={handleThemeSwitch}
    />
  )

  const items = useItems({ switchComponent })

  // Filter method.
  const filterFunction = useFilter(filter.value)

  // Children.
  const children = items.map(({ id, title, width, height, content, type }) => (
    <Item
      key={id}
      title={title}
      width={width}
      height={height}
      content={content}
      type={type}
    />
  ))

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Main>
        <Header>
          <Filters
            values={['home', 'projects', 'about']}
            activeFilter={filter}
            setFilter={setFilter}
          />
        </Header>

        <Routes>
          <Route
            exact
            path="/"
            element={
              <MuuriComponent
                {...options}
                propsToData={({ type }) => ({ type })}
                filter={filterFunction}
              >
                {children}
              </MuuriComponent>
            }
          />
          <Route exact path="/project/:id" element={<Project />} />
        </Routes>
      </Main>
    </ThemeProvider>
  )
}

ReactDom.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
)
