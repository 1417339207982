import styled from 'styled-components'

export const CardWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  font-size: 24px;
  font-weight: 300;
  color: #000;
  border-radius: 12px;
  background-color: ${p => p.theme.cardBg};
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -ms-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;

  :hover {
    box-shadow: 0px 0px 15px 0px darkgrey;
  }

  @media (max-width: 768px) {
    pointer-events: none;
  }
`

export const CardContainer = styled.div`
  width: 100%;
  height: 100%;
  color: ${p => p.theme.color};
  button {
    border: none;
    cursor: pointer;
  }
`

export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  height: 3rem;
  border-bottom: 1px solid rgba(189, 189, 189, 0.5);
`
export const CardTitle = styled.div`
  width: 100%;
  font-size: 15px;
  font-weight: 400;
`
