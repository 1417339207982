import { useCallback } from 'react'

// Return the filter method.
const useFilter = value => {
  return useCallback(
    function(data) {
      var isFilterMatch = value === 'all' ? true : data.type.includes(value)
      return isFilterMatch
    },
    [value]
  )
}

export default useFilter
