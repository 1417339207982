import React, { Fragment } from 'react'
import { MoonIcon, SunIcon } from '../../../assets/Icons'
import { ButtonContainer, Button, SwitchToggle, SwitchOption } from './style'

const Switch = ({ activeTheme, handleSwitch }) => {
  const isLightTheme = activeTheme === 0

  return (
    <Fragment>
      <ButtonContainer>
        <Button onClick={handleSwitch}>
          <SwitchToggle>
            <SwitchOption isLightTheme={isLightTheme}>
              <SunIcon />
              <MoonIcon />
            </SwitchOption>
          </SwitchToggle>
        </Button>
      </ButtonContainer>
    </Fragment>
  )
}

export default Switch
