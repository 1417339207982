import React from 'react'
import ReactWeather, { useOpenWeather } from 'react-open-weather'
import { WeatherContainer } from './style'

const customStyles = {
  fontFamily: 'Inter, sans-serif',
}

const Weather = () => {
  const { data, isLoading, errorMessage } = useOpenWeather({
    key: '9960000b729c4f23128b274f141297ec',
    lat: '-27.4806',
    lon: '-58.8341',
    lang: 'en',
    unit: 'metric', // values are (metric, standard, imperial)
  })
  return (
    <WeatherContainer>
      <ReactWeather
        theme={customStyles}
        isLoading={isLoading}
        errorMessage={errorMessage}
        data={data}
        lang="en"
        locationLabel="Corrientes"
        unitsLabels={{ temperature: '°C', windSpeed: 'Km/h' }}
        showForecast={false}
      />
    </WeatherContainer>
  )
}

export default Weather
