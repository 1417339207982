import React from 'react'

export const SunIcon = () => (
  <svg id="sun-icon" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M12 0C12.4624 0 12.8372 0.374831 12.8372 0.837209V1.95349C12.8372 2.41587 12.4624 2.7907 12 2.7907C11.5376 2.7907 11.1628 2.41587 11.1628 1.95349V0.837209C11.1628 0.374831 11.5376 0 12 0Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.5814 12C5.5814 8.4551 8.4551 5.5814 12 5.5814C15.5449 5.5814 18.4186 8.4551 18.4186 12C18.4186 15.5449 15.5449 18.4186 12 18.4186C8.4551 18.4186 5.5814 15.5449 5.5814 12ZM12 7.25581C9.37986 7.25581 7.25581 9.37986 7.25581 12C7.25581 14.6201 9.37986 16.7442 12 16.7442C14.6201 16.7442 16.7442 14.6201 16.7442 12C16.7442 9.37986 14.6201 7.25581 12 7.25581Z"
      fill="white"
    />
    <path
      d="M4.69873 3.51474C4.37178 3.18779 3.84169 3.18779 3.51473 3.51474C3.18778 3.84169 3.18778 4.37179 3.51473 4.69874L4.30406 5.48807C4.63101 5.81502 5.16111 5.81502 5.48806 5.48807C5.81501 5.16112 5.81501 4.63102 5.48806 4.30407L4.69873 3.51474Z"
      fill="white"
    />
    <path
      d="M24 12C24 12.4624 23.6252 12.8372 23.1628 12.8372H22.0465C21.5841 12.8372 21.2093 12.4624 21.2093 12C21.2093 11.5376 21.5841 11.1628 22.0465 11.1628H23.1628C23.6252 11.1628 24 11.5376 24 12Z"
      fill="white"
    />
    <path
      d="M20.4853 4.69873C20.8122 4.37178 20.8122 3.84169 20.4853 3.51473C20.1583 3.18778 19.6282 3.18778 19.3013 3.51473L18.5119 4.30406C18.185 4.63101 18.185 5.16111 18.5119 5.48806C18.8389 5.81501 19.369 5.81501 19.6959 5.48806L20.4853 4.69873Z"
      fill="white"
    />
    <path
      d="M12 21.2093C12.4624 21.2093 12.8372 21.5841 12.8372 22.0465V23.1628C12.8372 23.6252 12.4624 24 12 24C11.5376 24 11.1628 23.6252 11.1628 23.1628V22.0465C11.1628 21.5841 11.5376 21.2093 12 21.2093Z"
      fill="white"
    />
    <path
      d="M19.696 18.512C19.3691 18.185 18.839 18.185 18.512 18.512C18.1851 18.8389 18.1851 19.369 18.512 19.6959L19.3013 20.4853C19.6283 20.8122 20.1584 20.8122 20.4853 20.4853C20.8123 20.1583 20.8123 19.6282 20.4853 19.3013L19.696 18.512Z"
      fill="white"
    />
    <path
      d="M2.7907 12C2.7907 12.4624 2.41587 12.8372 1.95349 12.8372H0.837209C0.374831 12.8372 0 12.4624 0 12C0 11.5376 0.374831 11.1628 0.837209 11.1628H1.95349C2.41587 11.1628 2.7907 11.5376 2.7907 12Z"
      fill="white"
    />
    <path
      d="M5.48798 19.6959C5.81493 19.369 5.81493 18.8389 5.48798 18.5119C5.16103 18.185 4.63094 18.185 4.30399 18.5119L3.51466 19.3013C3.18771 19.6282 3.18771 20.1583 3.51466 20.4853C3.84161 20.8122 4.3717 20.8122 4.69865 20.4853L5.48798 19.6959Z"
      fill="white"
    />
  </svg>
)

export const MoonIcon = () => (
  <svg id="moon-icon" width="21" height="20" viewBox="0 0 21 20" fill="none">
    <path
      d="M19.6401 11C19.4958 10.8807 19.3208 10.8043 19.1352 10.7795C18.9496 10.7548 18.7607 10.7827 18.5901 10.86C17.5327 11.3439 16.383 11.5929 15.2201 11.59C13.069 11.5874 11.0061 10.7344 9.48127 9.2171C7.95644 7.69976 7.09333 5.64111 7.08014 3.49C7.0847 2.81583 7.16861 2.14454 7.33014 1.49C7.36441 1.31554 7.35156 1.13509 7.29289 0.967253C7.23423 0.799414 7.13187 0.65025 6.99638 0.53513C6.86088 0.42001 6.69714 0.343097 6.52203 0.312313C6.34692 0.28153 6.16677 0.29799 6.00014 0.359999C4.43246 1.06491 3.06971 2.15676 2.03989 3.53298C1.01008 4.9092 0.347015 6.52462 0.112984 8.22748C-0.121046 9.93034 0.081641 11.6647 0.702005 13.2678C1.32237 14.8708 2.34005 16.2898 3.65944 17.3915C4.97882 18.4932 6.55661 19.2414 8.24458 19.5658C9.93255 19.8903 11.6753 19.7803 13.3091 19.2462C14.9429 18.7122 16.4141 17.7716 17.5845 16.5128C18.755 15.254 19.5862 13.7183 20.0001 12.05C20.0506 11.859 20.0433 11.6573 19.9792 11.4705C19.9151 11.2836 19.7971 11.1199 19.6401 11ZM10.1401 17.69C8.46189 17.6782 6.82834 17.1479 5.46313 16.1717C4.09792 15.1956 3.0678 13.8213 2.51382 12.2371C1.95984 10.6529 1.90907 8.93618 2.36847 7.322C2.82788 5.70782 3.77499 4.2751 5.08014 3.22V3.49C5.08278 6.17848 6.15195 8.75609 8.053 10.6571C9.95404 12.5582 12.5317 13.6274 15.2201 13.63C15.926 13.6326 16.6301 13.5588 17.3201 13.41C16.63 14.7155 15.5966 15.8078 14.3313 16.5691C13.066 17.3304 11.6168 17.7318 10.1401 17.73V17.69Z"
      fill="white"
    />
  </svg>
)

export const InstagramIcon = () => (
  <svg
    id="instagram-icon"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_19_46)">
      <path
        d="M17.5 2H7.5C4.73858 2 2.5 4.23858 2.5 7V17C2.5 19.7614 4.73858 22 7.5 22H17.5C20.2614 22 22.5 19.7614 22.5 17V7C22.5 4.23858 20.2614 2 17.5 2Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5 11.37C16.6234 12.2022 16.4813 13.0522 16.0938 13.799C15.7063 14.5458 15.0931 15.1514 14.3416 15.5297C13.5901 15.9079 12.7384 16.0396 11.9078 15.9059C11.0771 15.7723 10.3098 15.3801 9.71484 14.7852C9.11992 14.1902 8.72773 13.4229 8.59407 12.5922C8.4604 11.7615 8.59207 10.9099 8.97033 10.1584C9.34859 9.40685 9.95419 8.79374 10.701 8.40624C11.4478 8.01874 12.2978 7.87658 13.13 8C13.9789 8.12588 14.7649 8.52146 15.3717 9.1283C15.9785 9.73515 16.3741 10.5211 16.5 11.37Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 6.5H18.01"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_19_46">
        <rect width="24" height="24" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
)

export const LinkedinIcon = () => (
  <svg
    id="linkedin-icon"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M16.5 8C18.0913 8 19.6174 8.63214 20.7426 9.75736C21.8679 10.8826 22.5 12.4087 22.5 14V21H18.5V14C18.5 13.4696 18.2893 12.9609 17.9142 12.5858C17.5391 12.2107 17.0304 12 16.5 12C15.9696 12 15.4609 12.2107 15.0858 12.5858C14.7107 12.9609 14.5 13.4696 14.5 14V21H10.5V14C10.5 12.4087 11.1321 10.8826 12.2574 9.75736C13.3826 8.63214 14.9087 8 16.5 8V8Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.5 9H2.5V21H6.5V9Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.5 6C5.60457 6 6.5 5.10457 6.5 4C6.5 2.89543 5.60457 2 4.5 2C3.39543 2 2.5 2.89543 2.5 4C2.5 5.10457 3.39543 6 4.5 6Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const WhatsAppIcon = () => (
  <svg
    id="whatsapp-icon"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M19.55 4.91C17.68 3.03 15.19 2 12.54 2C7.08 2 2.63 6.45 2.63 11.91C2.63 13.66 3.09 15.36 3.95 16.86L2.55 22L7.8 20.62C9.25 21.41 10.88 21.83 12.54 21.83C18 21.83 22.45 17.38 22.45 11.92C22.45 9.27 21.42 6.78 19.55 4.91ZM12.54 20.15C11.06 20.15 9.61 19.75 8.34 19L8.04 18.82L4.92 19.64L5.75 16.6L5.55 16.29C4.73 14.98 4.29 13.46 4.29 11.91C4.29 7.37 7.99 3.67 12.53 3.67C14.73 3.67 16.8 4.53 18.35 6.09C19.91 7.65 20.76 9.72 20.76 11.92C20.78 16.46 17.08 20.15 12.54 20.15ZM17.06 13.99C16.81 13.87 15.59 13.27 15.37 13.18C15.14 13.1 14.98 13.06 14.81 13.3C14.64 13.55 14.17 14.11 14.03 14.27C13.89 14.44 13.74 14.46 13.49 14.33C13.24 14.21 12.44 13.94 11.5 13.1C10.76 12.44 10.27 11.63 10.12 11.38C9.98 11.13 10.1 11 10.23 10.87C10.34 10.76 10.48 10.58 10.6 10.44C10.72 10.3 10.77 10.19 10.85 10.03C10.93 9.86 10.89 9.72 10.83 9.6C10.77 9.48 10.27 8.26 10.07 7.76C9.87 7.28 9.66 7.34 9.51 7.33C9.36 7.33 9.2 7.33 9.03 7.33C8.86 7.33 8.6 7.39 8.37 7.64C8.15 7.89 7.51 8.49 7.51 9.71C7.51 10.93 8.4 12.11 8.52 12.27C8.64 12.44 10.27 14.94 12.75 16.01C13.34 16.27 13.8 16.42 14.16 16.53C14.75 16.72 15.29 16.69 15.72 16.63C16.2 16.56 17.19 16.03 17.39 15.45C17.6 14.87 17.6 14.38 17.53 14.27C17.46 14.16 17.31 14.11 17.06 13.99Z" />
  </svg>
)

export const CopyIcon = () => (
  <svg id="copy-icon" width="13" height="15" viewBox="0 0 13 15" fill="none">
    <path d="M9.5 0.625H2C1.3125 0.625 0.75 1.1875 0.75 1.875V10.625H2V1.875H9.5V0.625ZM11.375 3.125H4.5C3.8125 3.125 3.25 3.6875 3.25 4.375V13.125C3.25 13.8125 3.8125 14.375 4.5 14.375H11.375C12.0625 14.375 12.625 13.8125 12.625 13.125V4.375C12.625 3.6875 12.0625 3.125 11.375 3.125ZM11.375 13.125H4.5V4.375H11.375V13.125Z" />
  </svg>
)

export const DownloadIcon = () => (
  <svg
    id="download-icon"
    width="14"
    height="13"
    viewBox="0 0 14 13"
    fill="none"
  >
    <path d="M12.625 7.75C12.4592 7.75 12.3003 7.81585 12.1831 7.93306C12.0658 8.05027 12 8.20924 12 8.375V10.875C12 11.0408 11.9342 11.1997 11.8169 11.3169C11.6997 11.4342 11.5408 11.5 11.375 11.5H2.625C2.45924 11.5 2.30027 11.4342 2.18306 11.3169C2.06585 11.1997 2 11.0408 2 10.875V8.375C2 8.20924 1.93415 8.05027 1.81694 7.93306C1.69973 7.81585 1.54076 7.75 1.375 7.75C1.20924 7.75 1.05027 7.81585 0.933058 7.93306C0.815848 8.05027 0.75 8.20924 0.75 8.375V10.875C0.75 11.3723 0.947544 11.8492 1.29917 12.2008C1.65081 12.5525 2.12772 12.75 2.625 12.75H11.375C11.8723 12.75 12.3492 12.5525 12.7008 12.2008C13.0525 11.8492 13.25 11.3723 13.25 10.875V8.375C13.25 8.20924 13.1842 8.05027 13.0669 7.93306C12.9497 7.81585 12.7908 7.75 12.625 7.75ZM6.55625 8.81875C6.61569 8.87565 6.68578 8.92025 6.7625 8.95C6.83731 8.98307 6.91821 9.00014 7 9.00014C7.08179 9.00014 7.16269 8.98307 7.2375 8.95C7.31422 8.92025 7.38431 8.87565 7.44375 8.81875L9.94375 6.31875C10.0614 6.20106 10.1276 6.04144 10.1276 5.875C10.1276 5.70856 10.0614 5.54894 9.94375 5.43125C9.82606 5.31356 9.66644 5.24744 9.5 5.24744C9.33356 5.24744 9.17394 5.31356 9.05625 5.43125L7.625 6.86875V0.875C7.625 0.70924 7.55915 0.550268 7.44194 0.433058C7.32473 0.315848 7.16576 0.25 7 0.25C6.83424 0.25 6.67527 0.315848 6.55806 0.433058C6.44085 0.550268 6.375 0.70924 6.375 0.875V6.86875L4.94375 5.43125C4.88548 5.37298 4.81629 5.32675 4.74016 5.29521C4.66402 5.26367 4.58241 5.24744 4.5 5.24744C4.41759 5.24744 4.33598 5.26367 4.25984 5.29521C4.18371 5.32675 4.11452 5.37298 4.05625 5.43125C3.99798 5.48952 3.95175 5.55871 3.92021 5.63484C3.88868 5.71098 3.87244 5.79259 3.87244 5.875C3.87244 5.95741 3.88868 6.03902 3.92021 6.11516C3.95175 6.19129 3.99798 6.26048 4.05625 6.31875L6.55625 8.81875Z" />
  </svg>
)

export const ExternalLink = () => (
  <svg
    fill="#000000"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="18px"
    height="18px"
  >
    <path d="M 16.707031 2.2929688 L 15.292969 3.7070312 L 17.585938 6 L 17 6 C 10.936593 6 6 10.936593 6 17 L 6 18 L 8 18 L 8 17 C 8 12.017407 12.017407 8 17 8 L 17.585938 8 L 15.292969 10.292969 L 16.707031 11.707031 L 21.414062 7 L 16.707031 2.2929688 z M 2 8 L 2 9 L 2 19 C 2 20.64497 3.3550302 22 5 22 L 19 22 C 20.64497 22 22 20.64497 22 19 L 22 18 L 22 17 L 20 17 L 20 18 L 20 19 C 20 19.56503 19.56503 20 19 20 L 5 20 C 4.4349698 20 4 19.56503 4 19 L 4 9 L 4 8 L 2 8 z" />
  </svg>
)
