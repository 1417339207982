const isDragEnabled = window.innerWidth > 768
// Grid static options.
export const options = {
  fillGaps: true,
  layoutOnResize: true,
  dragSortHeuristics: {
    sortInterval: 70,
  },
  layoutDuration: 400,
  dragRelease: {
    duration: 400,
    easing: 'ease-out',
  },
  dragEnabled: isDragEnabled,
  dragContainer: document.body,
  // The placeholder of an item that is being dragged.
  dragPlaceholder: {
    enabled: true,
    createElement: function(item) {
      // The element will have the Css class ".muuri-item-placeholder".
      return item.getElement().cloneNode(true)
    },
  },
  dragStartPredicate: function(item, hammerEvent) {
    // Don't drag if target is button
    if (
      hammerEvent.target.matches('button') ||
      hammerEvent.target.matches('svg')
    ) {
      return false
    }
    if (hammerEvent.target.matches('input')) {
      return false
    }
    if (hammerEvent.target.matches('a')) {
      return false
    }
    if (hammerEvent.target.matches('label')) {
      return false
    }
    if (hammerEvent.target.matches('img')) {
      return false
    }
    // handle Drag
    return true
  },
}
