import styled from 'styled-components'

export const ProjectContainer = styled.div`
  width: 100%;
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 5rem;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
`

export const DetailsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const DetailsImg = styled.div`
  display: flex;
  & img {
    width: 100%;
    margin-bottom: 20px;
  }
`

export const Button = styled.button.attrs(props => ({
  onClick: () => window.open(props.link, '_blank'),
}))`
  font-family: 'Inter', sans-serif;
  background-color: ${p => p.theme.buttonLinkProject};
  color: ${p => p.theme.color};
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 0px 20px;
  height: 30px;
  width: 160px;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
  border-radius: 100px;
  border: unset;
  margin: 0px 10px;
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  svg {
    fill: ${p => p.theme.color};
  }
`
