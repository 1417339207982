import React, { Fragment } from 'react'
import { CopyIcon, DownloadIcon } from '../../../assets/Icons'
import { InfoContainer, Text, ButtonsContainer, Download, Copy } from './style'

const Contact = () => {
  return (
    <Fragment>
      <InfoContainer>
        <Text>Send me an email</Text>
        <ButtonsContainer>
          <Download>
            My Resume <DownloadIcon />
          </Download>
          <Copy>
            azulmariapf@gmail.com <CopyIcon />
          </Copy>
        </ButtonsContainer>
      </InfoContainer>
    </Fragment>
  )
}

export default Contact
