import React, { Fragment } from 'react'
import { TagContainer, Tag } from './style'

const Skills = () => {
  return (
    <Fragment>
      <TagContainer>
        <Tag>Adobe Ai</Tag>
        <Tag>Adobe Ps</Tag>
        <Tag>Adobe Id</Tag>
        <Tag>Adobe Xd</Tag>
        <Tag>Adobe Ae</Tag>
        <Tag>Adobe Pr</Tag>
        <Tag>In Vision</Tag>
        <Tag>Figma</Tag>
        <Tag>Ilustration</Tag>
        <Tag>Html</Tag>
        <Tag>Css</Tag>
        <Tag>Bootstrap</Tag>
        <Tag>Git</Tag>
        <Tag>Bitbucket</Tag>
      </TagContainer>
    </Fragment>
  )
}

export default Skills
