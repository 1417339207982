import styled, { css } from 'styled-components'

const itemCommonStyles = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${p => p.theme.tagItemBg};
  width: inherit;
  border: unset;
  padding: 15px 20px;
  border-radius: 5px;
  margin: 10px 0px;
  font-size: 14px;
  font-weight: 400;
  color: ${p => p.theme.color};
  transition: all ease-out 0.2s;
`

export const InfoContainer = styled.div`
  width: 100%;
  padding: 20px;
`

export const Text = styled.div`
  font-size: 17px;
  font-weight: 400;
`

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    pointer-events: all;
  }
`

export const Download = styled.a.attrs({
  href: 'Resume_AzulPrieto.pdf',
  download: true,
})`
  ${itemCommonStyles};

  #download-icon {
    fill: ${p => p.theme.color};
    transition: fill ease-out 0.2s;
  }

  &:hover {
    color: #859be9;
    transition: color ease-out 0.2s;
    #download-icon {
      fill: #859be9;
      transition: fill ease-out 0.2s;
    }
  }
`

export const Copy = styled.button.attrs({
  onClick: () => {
    navigator.clipboard.writeText('azulmariapf@gmail.com')
    alert('Email copied')
  },
})`
  ${itemCommonStyles};

  #copy-icon {
    fill: ${p => p.theme.color};
    transition: fill ease-out 0.2s;
  }
`
