import React from 'react'
import {
  CardContainer,
  CardHeader,
  CardTitle,
  CardWrapper,
} from './cards/style'

const Item = ({ title, width, height, content }) => {
  const hasTitle = title !== ''

  return (
    <div className={`item h${height} w${width}`}>
      <div className="item-content">
        <CardWrapper>
          <CardContainer>
            {hasTitle && (
              <CardHeader>
                <CardTitle>{title}</CardTitle>
              </CardHeader>
            )}
            {content}
          </CardContainer>
        </CardWrapper>
      </div>
    </div>
  )
}

export default Item
