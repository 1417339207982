import styled from 'styled-components'
import customStyles from '../../../utils/customStyles'

const { orange, skyBlue } = customStyles

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  height: 110px;
  align-items: center;
  justify-content: center;
`

export const Button = styled.div`
  display: flex;
  align-items: center;
  width: 114px;
  height: 54px;
  padding: 9px 10px;
  background: ${p => p.theme.tagItemBg};
  border-radius: 29px;
  @media (max-width: 768px) {
    pointer-events: all;
  }
`

export const SwitchToggle = styled.button`
  width: 100%;
  height: 100%;
  position: relative;
  background: none;
  padding-inline: 0;
`

export const SwitchOption = styled.button`
  position: absolute;
  display: grid;
  place-content: center;
  height: 36px;
  width: 36px;
  border-radius: 50%;
  padding-inline: 0;
  top: 0;
  transition: all ease-out 0.3s;

  svg {
    position: absolute;
  }

  #sun-icon {
    opacity: 0;
    transition: all ease-out 0.3s;
    top: 6px;
    left: 6px;
  }
  #moon-icon {
    opacity: 0;
    transition: all ease-out 0.3s;
    top: 8px;
    left: 8px;
  }

  ${p =>
    p.isLightTheme
      ? `
      background-color: ${orange};
      transform: translateX(0);
      transition: all ease-out 0.3s;
      #sun-icon {
        opacity: 1;
        transition: all ease-out 0.3s;
      }
      `
      : `
      background-color: ${skyBlue};
      transform: translateX(59px);
      transition: all ease-out 0.3s;
      #moon-icon {
        opacity: 1;
        transition: all ease-out 0.3s;
      }
  `}
`
