import styled from 'styled-components'

export const FiltersWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  color: ${(p) => p.theme.color};

  a {
    color: unset;
    display: contents;
  }
  a:hover {
    color: unset;
    display: contents;
  }
`

export const TabLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 500;
  border-radius: 100px;
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  z-index: 3;

  ${(p) =>
    p.tab === 'home' &&
    `
			min-width: 82px;
		`};
  ${(p) =>
    p.tab === 'projects' &&
    `
			min-width: 98px;
		`};
  ${(p) =>
    p.tab === 'about' &&
    `
			min-width: 109px;
		`};
`

export const Glider = styled.span`
  position: absolute;
  display: flex;
  height: 36px;
  border-radius: 100px;
  transition: 0.25s ease-out;
`

export const FilterTabsContainer = styled.div`
  display: flex;
  position: relative;
  background-color: ${(p) => p.theme.filterTabBg};
  box-shadow: 0 0 1px 0 rgba(#185ee0, 0.15), 0 6px 12px 0 rgba(#185ee0, 0.15);
  border-radius: 100px;

  ${(p) =>
    p.activeFilter === 'home' &&
    `
			${Glider} {
				width: 82px;
				background-color: ${p.theme.homeTabBgActive};
				transform: translateX(0);
			}
		`};
  ${(p) =>
    p.activeFilter === 'projects' &&
    `
			${Glider} {
				width: 98px;
				background-color: ${p.theme.projectsTabBgActive};
				transform: translateX(80px);
			}
			`};
  ${(p) =>
    p.activeFilter === 'about' &&
    `
			${Glider} {
				width: 109px;
				background-color: ${p.theme.aboutMeTabBgActive};
				transform: translateX(179px);
			}
		`};
`
