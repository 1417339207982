export const customStyles = {
  orange: '#F2994A',
  skyBlue: '#859BE9',
  purple: '#7879F1',
  pink: '#F178B6',
  hazyPink: 'rgba(187, 107, 217, 0.55)',
}

export const lightTheme = {
  color: '#000',
  mainBg:
    'linear-gradient(137.27deg, #EBEAF0 31.14%, #DAC8F8 53.54%, #FFB6B6 65.77%, #FFD9B6 80.17%)',
  cardBg: 'rgba(255, 255, 255, 0.6)',
  filterTabBg: 'rgba(255, 255, 255, 0.3)',
  homeTabBgActive: 'white',
  projectsTabBgActive: 'white',
  aboutMeTabBgActive: 'white',
  tagItemBg: 'white',
  buttonLinkProject: 'white',
}

export const darkTheme = {
  color: '#fff',
  mainBg: 'linear-gradient(137.27deg, #353244 31.14%, #110426 89.19%)',
  cardBg: 'rgba(255, 255, 255, 0.1)',
  filterTabBg: 'rgba(255, 255, 255, 0.1)',
  homeTabBgActive: customStyles.orange,
  projectsTabBgActive: customStyles.purple,
  aboutMeTabBgActive: customStyles.pink,
  buttonLinkProject: customStyles.orange,
  tagItemBg: 'rgba(255, 255, 255, 0.2)',
}

export default customStyles
