import React from 'react'
import { Link } from 'react-router-dom'
import {
  FiltersWrapper,
  FilterTabsContainer,
  Glider,
  TabLabel,
} from './style.js'

const Filters = ({ values, activeFilter, setFilter }) => (
  <FiltersWrapper>
    <FilterTabsContainer activeFilter={activeFilter.value}>
      {values.map((value, i) => (
        <React.Fragment key={i}>
          <Link to={'../'}>
            <TabLabel
              tab={value}
              isActiveFilter={activeFilter.value === value}
              onClick={() => setFilter({ ...activeFilter, value: value })}
            >
              {value}
            </TabLabel>
          </Link>
        </React.Fragment>
      ))}
      <Glider />
    </FilterTabsContainer>
  </FiltersWrapper>
)

export default Filters
