import React from 'react'

// Images
import GreenBox from '../assets/GreenBox.png'
import DiRicci from '../assets/DiRicci.png'
import BuenClick from '../assets/BuenClick.png'
// import Aps from '../assets/APS.png'
// import Devlights from '../assets/Devlights.png'
// import Gym from '../assets/Gym.png'
// import PigRise from '../assets/PigRise.png'
// import Stopvector from '../assets/Stopvector.png'
import Publicala from '../assets/Publicala.png'
import Maate from '../assets/Maate.png'
import Research from '../assets/Research.png'

// Components
import Bio from '../components/cards/Bio'
import Contact from '../components/cards/Contact'
import Social from '../components/cards/Social'
import Weather from '../components/cards/Weather'
import Project from '../components/cards/Project'
import Skills from '../components/cards/Skills'
import Languages from '../components/cards/Languages'

const useItems = ({ switchComponent }) => [
  {
    id: 1,
    title: 'azulmariapf / Bio',
    width: 5,
    height: 5,
    content: <Bio />,
    type: ['home', 'about'],
  },
  {
    id: 2,
    title: 'Contact Me',
    width: 3,
    height: 3,
    content: <Contact />,
    type: ['home'],
  },
  {
    id: 3,
    title: 'Switch',
    width: 2,
    height: 2,
    content: switchComponent,
    type: ['home', 'projects', 'about'],
  },
  {
    id: 4,
    title: '',
    width: 5,
    height: 3,
    content: <Project image={Publicala} id={12} />,
    type: ['home', 'projects'],
  },
  {
    id: 5,
    title: '',
    width: 3,
    height: 1,
    content: <Social />,
    type: ['home', 'about'],
  },
  {
    id: 6,
    title: '',
    width: 5,
    height: 3,
    content: <Project image={Maate} id={13} />,
    type: ['home', 'projects'],
  },
  {
    id: 7,
    title: '',
    width: 5,
    height: 3,
    content: <Project image={GreenBox} id={1} />,
    type: ['projects'],
  },
  {
    id: 8,
    title: '',
    width: 3,
    height: 3,
    content: <Weather />,
    type: ['home'],
  },
  {
    id: 9,
    title: 'Languages',
    width: 4,
    height: 2,
    content: <Languages />,
    type: ['about'],
  },
  {
    id: 10,
    title: 'Skills',
    width: 4,
    height: 3,
    content: <Skills />,
    type: ['about'],
  },
  {
    id: 11,
    title: '',
    width: 4,
    height: 3,
    content: <Project image={DiRicci} id={2} />,
    type: ['projects'],
  },
  {
    id: 12,
    title: '',
    width: 3,
    height: 4,
    content: <Project image={BuenClick} id={7} />,
    type: ['projects'],
  },
  {
    id: 13,
    title: '',
    width: 5,
    height: 3,
    content: <Project image={Research} id={14} />,
    type: ['projects'],
  },
  // {
  //   id: 9,
  //   title: '',
  //   width: 3,
  //   height: 2,
  //   content: <Project image={Stopvector} id={8} />,
  //   type: ['projects'],
  // },
  // {
  //   id: 10,
  //   title: '',
  //   width: 3,
  //   height: 3,
  //   content: <Project image={Aps} id={6} />,
  //   type: ['projects'],
  // },
  // {
  //   id: 11,
  //   title: '',
  //   width: 5,
  //   height: 3,
  //   content: <Project image={Devlights} id={3} />,
  //   type: ['projects'],
  // },
  // {
  //   id: 12,
  //   title: '',
  //   width: 3,
  //   height: 3,
  //   content: <Project image={PigRise} id={5} />,
  //   type: ['projects'],
  // },
  // {
  //   id: 13,
  //   title: '',
  //   width: 5,
  //   height: 3,
  //   content: <Project image={Gym} id={4} />,
  //   type: ['projects'],
  // },
]

export default useItems
