import styled from 'styled-components'

export const ImageContainer = styled.div`
  width: 100%;
`
export const Image = styled.img.attrs({
  src: 'avatar.png',
  alt: 'Avatar',
})`
  margin: 20px;
`
export const TextContainer = styled.div`
  display: grid;
  width: 100%;
  row-gap: 5px;
`

export const Title = styled.div`
  font-size: 15px;
  font-weight: 600;
`

export const SubTitle = styled.div`
  font-size: 13px;
`

export const Text = styled.div`
  font-size: 15px;
  font-weight: 300;
  padding-inline: 22px;

  @media (max-width: 425px) {
    font-size: 13px;
    padding-inline: 24px;
  }
`
