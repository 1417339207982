import styled from 'styled-components'

export const TagContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  justify-content: center;
`
export const Tag = styled.button`
  background-color: ${(p) => p.theme.tagItemBg};
  width: fit-content;
  border: unset;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(p) => p.theme.color};
  margin: 6px;
  padding: 5px 20px;
  @media (max-width: 480px) {
    padding: 2px 10px;
  }
`
