import styled from 'styled-components'

export const ProjectContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background-image: url(${props => props.image});
  background-size: cover;
`

export const Button = styled.button`
  background-color: #fff;
  width: 38px;
  height: 38px;
  border: unset;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
  @media (max-width: 768px) {
    pointer-events: all;
  }
`
