import styled from 'styled-components'

export const TagContainer = styled.div`
  width: 100%;
  height: fit-content;
  padding: 30px 40px;
  display: flex;
  justify-content: space-around;
  align-items: center;
`

export const TagBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Tag = styled.div`
  font-size: 15px;
  font-weight: 400;
  margin: 8px 0px;
`
