import React from 'react'
import {
  InstagramIcon,
  LinkedinIcon,
  WhatsAppIcon,
} from '../../../assets/Icons'
import { SocialContainer, Link } from './style'

const Social = () => {
  return (
    <SocialContainer>
      <Link url={'https://www.linkedin.com/in/azulmariaprieto/'}>
        <LinkedinIcon />
      </Link>
      <Link url={'https://instagram.com/bitesdigital?igshid=YmMyMTA2M2Y='}>
        <InstagramIcon />
      </Link>
      <Link url={'https://wa.me/5493795083111'}>
        <WhatsAppIcon />
      </Link>
    </SocialContainer>
  )
}

export default Social
