import styled from 'styled-components'

export const SocialContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;

  #instagram-icon {
    path {
      stroke: ${p => p.theme.color};
      transition: stroke ease-out 0.2s;
    }
  }

  #linkedin-icon {
    path {
      stroke: ${p => p.theme.color};
      transition: stroke ease-out 0.2s;
    }
  }

  #whatsapp-icon {
    path {
      fill: ${p => p.theme.color};
      transition: fill ease-out 0.2s;
    }
  }
`

export const Link = styled.a.attrs(props => ({
  href: `${props.url}`,
  target: '_blank',
  rel: 'noopener noreferrer',
}))`
  display: flex;
  justify-content: center;
  align-items: center;
  width: inherit;
  @media (max-width: 768px) {
    pointer-events: all;
  }
`
