import React from 'react'
import { useParams } from 'react-router-dom'
import { ExternalLink } from '../../assets/Icons'
import {
  ProjectContainer,
  Header,
  Title,
  DetailsContainer,
  DetailsImg,
  Button,
} from './style'

const Project = () => {
  let { id } = useParams()

  const projects = [
    {
      id: 1,
      title: 'Green Box',
      url: 'https://greenbox-v3.netlify.app/',
      images: ['Project-details-1.webp'],
    },
    {
      id: 2,
      title: 'Ecommerce - Saverio di ricci',
      url: 'https://www.saveriodiriccistore.com/',
      images: ['Project-details-2.webp'],
    },
    {
      id: 3,
      title: 'Devlights web site',
      url: null,
      images: ['Project-details-3.webp'],
    },
    {
      id: 4,
      title: 'Rebrand - Gym-swype',
      url: null,
      images: [
        'Project-details-4-1.webp',
        'Project-details-4-2.webp',
        'Project-details-4-3.webp',
        'Project-details-4-4.webp',
        'Project-details-4-5.webp',
        'Project-details-4-6.webp',
      ],
    },
    {
      id: 5,
      title: 'PigRise web site',
      url: null,
      images: [
        'Project-details-5-1.webp',
        'Project-details-5-2.webp',
        'Project-details-5-3.webp',
        'Project-details-5-4.webp',
      ],
    },
    {
      id: 6,
      title: 'Ecommerce - APS - Security System',
      url: null,
      images: ['Project-details-6.webp'],
    },
    {
      id: 7,
      title: 'APP - Digital Card - BC',
      url: null,
      images: [
        'Project-details-7-1.webp',
        'Project-details-7-2.webp',
        'Project-details-7-3.webp',
      ],
    },
    {
      id: 8,
      title: 'APP - Stopvector',
      url: null,
      images: ['Project-details-8.webp'],
    },
    {
      id: 9,
      title: 'Ecommerce y web site institucional - Meucci Automotores',
      url: null,
      images: [
        'Project-details-9-1.webp',
        'Project-details-9-2.webp',
        'Project-details-9-3.webp',
      ],
    },
    {
      id: 10,
      title: 'Branding - INNEW Agencia de Publicidad',
      url: null,
      images: [
        'Project-details-10-1.webp',
        'Project-details-10-2.webp',
        'Project-details-10-3.webp',
        'Project-details-10-4.webp',
        'Project-details-10-5.webp',
        'Project-details-10-6.webp',
        'Project-details-10-7.webp',
        'Project-details-10-8.webp',
        'Project-details-10-9.webp',
        'Project-details-10-10.webp',
      ],
    },
    {
      id: 11,
      title: "Campaña  contra el bulliying escolar 'MONSTERS'",
      url: null,
      images: [
        'Project-details-11-1.webp',
        'Project-details-11-2.webp',
        'Project-details-11-3.webp',
        'Project-details-11-4.webp',
        'Project-details-11-5.webp',
        'Project-details-11-6.webp',
        'Project-details-11-7.webp',
        'Project-details-11-8.webp',
      ],
    },
    {
      id: 12,
      title: 'Publicala',
      url:
        'https://play.google.com/store/apps/details?id=la.publica.reader&hl=es_AR&gl=US',
      images: ['Project-details-12.webp'],
    },
    {
      id: 13,
      title: "Maate.gg'",
      url: null,
      images: [
        'Project-details-13-1.webp',
        'Project-details-13-2.webp',
        'Project-details-13-3.webp',
        'Project-details-13-4.webp',
        'Project-details-13-5.webp',
        'Project-details-13-6.webp',
      ],
    },
    {
      id: 13,
      title: 'Research',
      url: null,
      images: [
        'Project-details-14-1.png',
        'Project-details-14-2.png',
        'Project-details-14-3.png',
      ],
    },
  ]

  return (
    <ProjectContainer>
      <Header>
        <Title>{projects[--id].title}</Title>
        {projects[id].url && (
          <Button link={projects[id].url}>
            See project <ExternalLink />
          </Button>
        )}
      </Header>
      <DetailsContainer>
        {projects[id].images.map((image, index) => (
          <DetailsImg>
            <img
              src={require('../../assets/projects-details/' + image)}
              alt={'Details ' + ++index}
            />
          </DetailsImg>
        ))}
      </DetailsContainer>
    </ProjectContainer>
  )
}

export default Project
