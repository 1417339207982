import React from 'react'
import { Link } from 'react-router-dom'

const Header = ({ children }) => {
  return (
    <div className="controls">
      <div className="avatarContainer">
        <Link to={'../'}>
          <img src="avatar.png" alt="Avatar" width="45px" height="45px" />
        </Link>
      </div>
      {children}
    </div>
  )
}

export default Header
